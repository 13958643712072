import parse from 'html-react-parser'
import Blocks from '@/components/molecules/Blocks'
import PropTypes from 'prop-types'

/**
 * Stackable Call To Action
 *
 * A .
 *
 * @author RKC
 * @param  {object}  props                 The component properties.
 * @param  {object}  props.innerBlocks     The array of inner blocks to display.
 * @param  {string}  props.originalContent Original prerendered content.
 * @return {Element}                       The Feature component.
 */
export default function StkBlockCallToAction({innerBlocks, originalContent}) {
  // Get Wordpress source media URL to be replaced registered in env.
  // let sourceURL = process.env.NEXT_WORDPRESS_SOURCE_MEDIA_URL

  // Get S3 Wordpress media URL to replace with registered in env.
  // let s3URL = process.env.NEXT_WORDPRESS_S3_MEDIA_URL

  const options = {
    replace: ({attribs, children}) => {
      if (!attribs) {
        return
      }
      // Replace the source Wordpress media URL with S3 URL
      if (children && children.length) {
        children.forEach((element) => {
          if (element.type === 'text') {
            // element.data = element.data.replace(sourceURL, s3URL)
            element.data = element.data.replace(
              'https://sharqinsurance.web2box.com/wp-content',
              'https://s3.eu-west-1.amazonaws.com/web2box.com'
            )
            return element
          }
        })
      }
      const classes = attribs.class
      if (classes && classes.includes('stk-inner-blocks')) {
        return (
          <>
            <div className={classes}>
              {!!innerBlocks?.length && <Blocks blocks={innerBlocks} />}
            </div>
          </>
        )
      }
    }
  }
  return <>{parse(originalContent, options)}</>
}

StkBlockCallToAction.propTypes = {
  innerBlocks: PropTypes.arrayOf(
    PropTypes.shape({
      block: PropTypes.object,
      index: PropTypes.number
    })
  ),
  originalContent: PropTypes.string
}
